export const environment = {
	env: 'development',
	intercomAppId: 'ssl1wxqp',
	production: false,
	googleMapsAPIKey: 'AIzaSyC4OOpkzFJ-6WFzRHcBqG-H7XYOjxs7iaI',
	stripePublishableAPIKey: 'pk_test_51J5Kh0EgCSMDz09z954E4iv4eg7sLGMT7uLao43bHcwhO3fZSGsQfVD5OW5KMOfIkWHMqTY2K8FDm1UWjyLzG4ea00an4BitbW',
	colors: {
		primary0: '#0EABF7',
		primary: '#078BCB',
		primary2: '#067ab2',
		primary3: '#05699a',
		accentWhite: '#eff6ff',
		accent1: '#bfdbfe',
		accent2: '#60a5fa',
		lightPrimary: '#339bff26'
	},
	stripePlans: [
		{ plan: 'Tradie', key: 'price_1PXgUGEgCSMDz09zBJqaWo4Z' },
		{ plan: 'Go Getter', key: 'price_1PXi5hEgCSMDz09z1i2YEJwr' },
		{ plan: 'Professional', key: 'price_1O2kEXEgCSMDz09zSlGwCv7l' },
		{ plan: 'Business', key: 'price_1O2kD3EgCSMDz09zf8OfT76K' },
	],
	posthog: true,
	countries: ['australia'],
	us: false,
};
